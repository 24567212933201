import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MapFetchService } from './map-fetch.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnInit {
  @ViewChild("map") map;
  crntYear = 0;
  markers = [];
  zoom = 11;
  data = [];
  crntIssue = 'All';
  towerName;
  zoomControl = false;
  scrollwheel = true;
  disableDoubleClickZoom = true;
  maxZoom = 15;
  minZoom = 1;
  disableDefaultUI = true;
  // center = { lat: -24, lng: 134 }
  center = { lat: 0, lng: 0 }
  labelShow = true;
  role;
  constructor(private service: MapFetchService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnChange() {
  }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.service.fetchData().forEach(data => {
      data.forEach(doc => {
        this.data.push(doc.data());
      })

      // console.log('this.data - ', this.data);
      let allTowers = this.data[0].Towers
      let latSum=0, longSum=0, latAvg=0, longAvg=0;

      for (let index = 0; index < allTowers.length; index++) {
        latSum = latSum+allTowers[index].Latitude;
        longSum = longSum+allTowers[index].Longitude;
      }
      latAvg = latSum/allTowers.length;
      longAvg = longSum/allTowers.length;

      console.log('latAvg - ', latAvg);
      console.log('longAvg - ', longAvg);
      this.center.lat= latAvg;
      this.center.lng= longAvg;
    })
  }
  changeRoute(Tower, status) {
    this.towerName = Tower.replaceAll(" ", '');
    console.log('Tower click - ', this.towerName);
    if (status == 'Inspected' && this.role !== 'pilot') {
      this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + this.towerName + '/summary']);
    }
    else if (status === 'Uninspected' && this.role === 'pilot') {
      this.router.navigate(['/'+this.role+'/'+ this.data[this.crntYear].Year  +'/' +this.towerName+ '/upload']);
    } else {
      this.snackBar.open('This Site is not Inspected yet!', "close", { duration: 2000 });
    }
  }


  zoomIn() {
    if (this.zoom < this.maxZoom) this.zoom++;
  }

  zoomOut() {
    if (this.zoom > this.minZoom) this.zoom--;
  }


  // removeLabel(){
  //   if(this.labelShow){
  //     this.labelShow = false;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "none";
  //   }else{
  //     this.labelShow = true;
  //     this.map._elem["nativeElement"].querySelector(".gm-style-iw-a").style.display = "block";
  //   }
  // }

  all(record) {
    return true;
  }

  uninspected(record) {
    return record.Status == "Uninspected";
  }
  inspected(record) {
    return record.Status == "Inspected";
  }
  completed(record) {
    return record.Completed == "Yes";
  }
  emergency(record) {
    return record.Status == "Emergency";
  }
  urgent(record) {
    return record.Status == "Urgent";
  }
  planned(record) {
    return record.Status == "Planned";
  }
  monitor(record) {
    return record.Status == "Monitor";
  }



  pole(record) {
    return record.StructureType == "Pole";
  }

  mast(record) {
    return record.StructureType == "Mast";
  }

  tower(record) {
    return record.StructureType == "Tower";
  }


  heavy(record) {
    return record.Corrosion == "Heavy";
  }

  medium(record) {
    return record.Corrosion == "Medium";
  }

  light(record) {
    return record.Corrosion == "Light";
  }

  surface(record) {
    return record.Corrosion == "Surface";
  }

  cvNo(record) {
    return record.CanradVariation == "No";
  }

  cvYes(record) {
    return record.CanradVariation == "Yes";
  }


  onMouseOver(infoWindow, $event: MouseEvent) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, $event: MouseEvent) {
    infoWindow.close();
  }

  clickEvent(event) {
    event.preventDefault();
  }

}
