import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

import { GuestComponent } from './roles/guest/guest.component';
import { GuestGuard } from './roles/guest/guest.guard';
import { AeroAdminComponent } from './roles/aero-admin/aero-admin.component';
import { AeroAdminGuard } from './roles/aero-admin/aero-admin.guard';
import { PilotComponent } from './roles/pilot/pilot.component';
import { PilotGuard } from './roles/pilot/pilot.guard';
import { TelstraAdminComponent } from './roles/telstra-admin/telstra-admin.component';
import { TelstraAdminGuard } from './roles/telstra-admin/telstra-admin.guard';
import { UnsetComponent } from './components/unset/unset.component';
import { UnsetGuard } from './components/unset/unset.guard';
import { SuperAdminComponent } from './roles/super-admin/super-admin.component';
import { SuperAdminGuard } from './roles/super-admin/super-admin.guard';
import { SignInGuard } from './components/sign-in/sign-in.guard';
import { SignUpGuard } from './components/sign-up/sign-up.guard';


import { DigitaltwinComponent } from './components/digitaltwin/digitaltwin.component';
import { DocsComponent } from './components/docs/docs.component';
import { OrthomapComponent } from './components/orthomap/orthomap.component';
import { SpinViewComponent } from './components/spin-view/spin-view.component';
import { SummaryComponent } from './components/summary/summary.component';
import { TowerAuditComponent } from './components/tower-audit/tower-audit.component';
import { MapComponent } from './components/map/map.component';
import { TableComponent } from './components/table/table.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { UsersComponent } from './components/users/users.component';

import { UploadComponent } from './components/upload/upload.component';
import { CableViewComponent } from './components/cable-view/cable-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo : '/sign-in',
    pathMatch : 'full'
  },
  {
    path : 'sign-in',
    canActivate : [SignInGuard],
    component : SignInComponent
  },
  {
    path : 'sign-up',
    canActivate : [SignUpGuard],
    component : SignUpComponent
  },
  {
    path : 'forgot-password',
    component : ForgotPasswordComponent
  },
  {
    path : 'user',
     canActivate : [GuestGuard],
    component: GuestComponent,
    children:[
      {
        path:":year/:tower/summary",
        component: SummaryComponent
      },
      {
        path:":year/:tower/audit",
        component:TowerAuditComponent
      },
      {
        path:":year/:tower/orthomap",
        component:OrthomapComponent
      },
      {
        path:":year/:tower/digitaltwin",
        component:DigitaltwinComponent
      },
      {
        path:":year/:tower/view/:view",
        component:SpinViewComponent
      },
      {
        path:":year/:tower/cableview",
        component:CableViewComponent
      },
      {
        path:":year/:tower/docs",
        component:DocsComponent
      },
      {
        path:":year/:tower/gallery",
        component:GalleryComponent
      },
      {
        path:"table",
        component:TableComponent
      },
      {
        path:"map",
        component:MapComponent
      },
      {
        path: "*",
        redirectTo : '/user/map'
      }
    ]
  },
  {
    path : 'unset',
    canActivate : [UnsetGuard],
    component: UnsetComponent
  },
  {
    path : 'pilot',
    canActivate : [PilotGuard],
    component : PilotComponent,
    children:[
      {
        path:":year/:tower/upload",
        component: UploadComponent
      },
      {
        path:":year/:tower/summary",
        component: SummaryComponent
      },
      {
        path:":year/:tower/audit",
        component:TowerAuditComponent
      },
      {
        path:":year/:tower/orthomap",
        component:OrthomapComponent
      },
      {
        path:":year/:tower/digitaltwin",
        component:DigitaltwinComponent
      },
      {
        path:":year/:tower/view/:view",
        component:SpinViewComponent
      },
      {
        path:":year/:tower/cableview",
        component:CableViewComponent
      },
      {
        path:":year/:tower/docs",
        component:DocsComponent
      },
      {
        path:":year/:tower/gallery",
        component:GalleryComponent
      },
      {
        path:"table",
        component:TableComponent
      },
      {
        path:"map",
        component:MapComponent
      },
      {
        path: "*",
        redirectTo : '/pilot/map'
      }
    ]
  },
  {
    path : 'telstra-processing',
   canActivate : [TelstraAdminGuard],
    component : TelstraAdminComponent,
    children:[
      {
        path:":year/:tower/summary",
        component: SummaryComponent
      },
      {
        path:":year/:tower/audit",
        component:TowerAuditComponent
      },
      {
        path:":year/:tower/orthomap",
        component:OrthomapComponent
      },
      {
        path:":year/:tower/digitaltwin",
        component:DigitaltwinComponent
      },
      {
        path:":year/:tower/view/:view",
        component:SpinViewComponent
      },
      {
        path:":year/:tower/cableview",
        component:CableViewComponent
      },
      {
        path:":year/:tower/docs",
        component:DocsComponent
      },
      {
        path:":year/:tower/gallery",
        component:GalleryComponent
      },
      {
        path:"table",
        component:TableComponent
      },
      {
        path:"map",
        component:MapComponent
      },
      {
        path: "*",
        redirectTo : '/telstra-processing/map'
      }
    ]
  },
  {
    path : 'aero-processing',
  canActivate : [AeroAdminGuard],
    component : AeroAdminComponent,
    children:[
      {
        path:":year/:tower/summary",
        component: SummaryComponent
      },
      {
        path:":year/:tower/audit",
        component:TowerAuditComponent
      },
      {
        path:":year/:tower/orthomap",
        component:OrthomapComponent
      },
      {
        path:":year/:tower/digitaltwin",
        component:DigitaltwinComponent
      },
      {
        path:":year/:tower/view/:view",
        component:SpinViewComponent
      },
      {
        path:":year/:tower/cableview",
        component:CableViewComponent
      },
      {
        path:":year/:tower/docs",
        component:DocsComponent
      },
      {
        path:":year/:tower/gallery",
        component:GalleryComponent
      },
      {
        path:"table",
        component:TableComponent
      },
      {
        path:"map",
        component:MapComponent
      },
      {
        path: "*",
        redirectTo : '/aero-processing/map'
      }
    ]
  },
  {
    path : 'super-admin',
  canActivate : [SuperAdminGuard],
    component : SuperAdminComponent,
    children:[
      {
        path:":year/:tower/summary",
        component: SummaryComponent
      },
      {
        path:":year/:tower/audit",
        component:TowerAuditComponent
      },
      {
        path:":year/:tower/orthomap",
        component:OrthomapComponent
      },
      {
        path:":year/:tower/digitaltwin",
        component:DigitaltwinComponent
      },
      {
        path:":year/:tower/view/:view",
        component:SpinViewComponent
      },
      {
        path:":year/:tower/cableview",
        component:CableViewComponent
      },
      {
        path:":year/:tower/docs",
        component:DocsComponent
      },
      {
        path:":year/:tower/gallery",
        component:GalleryComponent
      },
      {
        path:"table",
        component:TableComponent
      },
      {
        path:"map",
        component:MapComponent
      },
      {
        path:"users",
        component:UsersComponent
      },
      {
        path: "*",
        redirectTo : '/super-admin/map'
      }
    ]
  },
  {
    path: '*',
    redirectTo : '/sign-in',
    pathMatch : 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
