import { Component, OnInit, ViewChild, HostListener, ViewEncapsulation } from '@angular/core';
import { DigitaltwinFetchService } from './digitaltwin-fetch.service';
import { ActivatedRoute, Router } from "@angular/router";
import * as THREE from 'three';
import { OrbitControls } from '@avatsaev/three-orbitcontrols-ts';
import { DragControls } from 'three/examples/jsm/controls/DragControls'; // DragControls.js,drag-controls.js
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MatSnackBar } from '@angular/material/snack-bar';
//import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';

import { EditComponent } from './../edit/edit.component'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ShelterModalComponent } from './shelter-modal/shelter-modal.component';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-digitaltwin',
  templateUrl: './digitaltwin.component.html',
  styleUrls: ['./digitaltwin.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class DigitaltwinComponent implements OnInit {
  year;
  Link;
  urlSafe;
  displayedColumns = ["Name", "NameEdit", "Value", "ValueEdit", "Image", "ImageEdit"];
  displayedColumnsUser =  ["Name", "Value", "Image"];
  selectedFeature = 1;
  selectedShelter = null;
  selectedEquipment = null;
  renderer;
  scene;
  loaded = false;
  camera;
  role;
  controls;
  siteData;
  auditData;
  outDoorEquipments = [];
  cadData = [];
  equipmentAlias = [];
  cameraData;
  pointCloudData;
  assetDataIndex;
  leg;
  tower;
  imageUpdateArray = [];
  updatedValue = '';
  updatedOption;
  towers = [];
  telcoObjGrp;
  pointsCloud;
  transformControl;
  box;
  assetData;
  dragControls;
  isOpen = false;
  assets = [];
  reqAnimGlobalId = 0;
  stepsAbc = 0;
  initialPosAbc;
  finalPosAbc;
  lookAtObjAbc;
  newPositionAbc;
  addEquipment = '';
  detailImageIndex = 0;
  sliderValue = 0;
  mouse = new THREE.Vector2();
  crntImg = 0;
  raycaster = new THREE.Raycaster();
  @ViewChild("container") container;
  isLoaded = true;
  abc = null;
  gizmoControl; gizmoClicked = false; antennaClicked = 0;
  linePts = []; objects = []; sphere;
  editMode = 'Add';
  structureType; structureHeight; latitude; longitude;

  constructor(private router: Router, private snackBar: MatSnackBar, private dialog: MatDialog, private service: DigitaltwinFetchService, private route: ActivatedRoute, public sanitizer: DomSanitizer) {

    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(query => {
      this.tower = query.tower;
      this.year = query.year;
      if (this.tower == 'ESPERANCERT') {
        this.linePts.push({ "x1": 1.167579319660474, "y1": 28.67686424450075, "z1": 1.1512776277898586, "x2": 1.3819896058106078, "y2": 15.469401179448381, "z2": 1.5101677585009305 });
        this.linePts.push({ "x1": -1.1388925499598077, "y1": 28.753859720203486, "z1": 1.1171983778938959, "x2": -1.4614762551768727, "y2": 15.469401179448381, "z2": 1.3836076765142185 });
        this.linePts.push({ "x1": -1.1388925499598077, "y1": 28.753859720203486, "z1": -1.2337058013349882, "x2": -1.4614762551768727, "y2": 15.469401179448381, "z2": -1.5095117201002637 });
        this.linePts.push({ "x1": 1.1813431261414022, "y1": 28.67686424450075, "z1": -1.2556783919788286, "x2": 1.3819896058106078, "y2": 15.469401179448381, "z2": -1.5095117201002637 });

        this.linePts.push({ "x1": 1.3819896058106078, "y1": 15.469401179448381, "z1": 1.5101677585009305, "x2": 5.277883966756319, "y2": -40.20255394338287, "z2": 5.510888003045423 });
        this.linePts.push({ "x1": -1.4614762551768727, "y1": 15.469401179448381, "z1": 1.3836076765142185, "x2": -5.46967862992345, "y2": -40.10200028236667, "z2": 5.36465555507443 });
        this.linePts.push({ "x1": -1.4614762551768727, "y1": 15.469401179448381, "z1": -1.5095117201002637, "x2": -5.46967862992345, "y2": -40.10200028236667, "z2": -5.288295338630794 });
        this.linePts.push({ "x1": 1.3819896058106078, "y1": 15.469401179448381, "z1": -1.5095117201002637, "x2": 5.2740565617943265, "y2": -40.10200028236667, "z2": -5.227822538192429 });
      }
    })
    this.service.setData(this.tower).get().subscribe(
      (response) => {
        let data = response.data();
        this.Link = data['DigitalTwin']['Link'];
        if (this.Link && this.Link != "") {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.Link);
        } 
        else {
          this.Link = null;
          this.siteData = data['Audit']['General'][1].Details;
          this.structureType = data['Audit']['General'][3].Details[0].Value; 
          this.structureHeight = data['Audit']['General'][3].Details[1].Value; 
          this.latitude = data['Audit']['General'][2].Details[3].Value; 
          this.longitude = data['Audit']['General'][2].Details[4].Value;
          this.auditData = data['Audit'];
          this.outDoorEquipments = data['DigitalTwin']['OutDoorEquipment'];
          this.cadData = data['DigitalTwin']['CAD'];
          this.cameraData = data['DigitalTwin']['Camera'];
          this.equipmentAlias = data['DigitalTwin']['Equipment']
          this.main();
        }
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
      }
    )

  }

  ngOnInit(): void {
  }

  nextImg() {
    if (this.crntImg != this.assetData.Details[this.detailImageIndex].Image.length - 1) {
      this.crntImg++;
    } else {
      this.crntImg = 0;
    }
  };

  prevImg() {
    if (this.crntImg != 0) {
      this.crntImg--;
    } else {
      this.crntImg = this.assetData.Details[this.detailImageIndex].Image.length - 1;
    }
  }


  animate() {
    this.renderer.render(this.scene, this.camera);
    window.requestAnimationFrame(() => this.animate());
  }


  toggleEquipments(name, index = null) {
    this.detailImageIndex = 0;
    this.isOpen = false;
    // console.log('name - ', name);
    // console.log('this.equipmentAlias[i]. - ', this.equipmentAlias);
    for (let i = 0; i < this.equipmentAlias.length; i++) {
      if (this.equipmentAlias[i].Alias == name) {
        // console.log('this.equipmentAlias[i].Alias - ', this.equipmentAlias[i].Alias);

        this.crntImg = 0;
        this.selectedFeature = 3;
        this.selectedEquipment = index;
        this.selectAsset(this.equipmentAlias[i].Name);
        this.assetDetails(name, index)
        this.isOpen = true;
      }
    }
  }

  assetDetails(elt, index) {
    for (let i = 0; i < this.auditData.Equipment.length; i++) {
      if (this.auditData.Equipment[i].Name == elt) {
        this.assetDataIndex = i;
        this.assetData = this.auditData.Equipment[i];
      }
    }
    //  this.assetDataIndex = index;
    //  this.assetData = this.auditData.Equipment[index];
  }

  selectAsset(elt) {
    console.log('elt - ', elt)
    if (this.box) {
      this.scene.remove(this.box);
    }
    let objNow = this.scene.getObjectByName(elt);
    this.box = new THREE.BoxHelper(objNow, "#40BB47"); //0xffff00 //rgb(17, 171, 198)
    this.box.geometry.computeBoundingBox();
    this.scene.add(this.box);

    let bbbox = new THREE.Box3();
    bbbox
      .copy(this.box.geometry.boundingBox)
      .applyMatrix4(this.box.matrixWorld);
    let posNw = new THREE.Vector3(bbbox.max.x, bbbox.max.y, bbbox.max.z);

    this.changeCameraPositionWithRequestAnimationFrame(posNw.clone(), bbbox.max.x, bbbox.max.y, bbbox.max.z);
  }

  toggleWaves(event, elt) {
    if (event.path[0].style.color == "grey") {
      event.path[0].removeAttribute("style")
      event.path[0].setAttribute("style", "color:#19b621");
    } else {
      event.path[0].removeAttribute("style")
      event.path[0].setAttribute("style", "color:grey");
    }
    this.waveVisibility(elt);
  }

  toggleAsset(event, elt) {
    let parent = event.target.parentElement;
    if (parent.children[0].style.display === "none") {
      parent.children[0].setAttribute("style", "display:block");
      event.path[0].setAttribute("style", "display:none");

    }
    if (parent.children[1].style.display === "none") {
      parent.children[1].setAttribute("style", "display:block");
      event.path[0].setAttribute("style", "display:none");

    }
    this.assetVisibility(elt);
  }


  assetVisibility(elt) {
    let asset = this.scene.getObjectByName(elt);
    asset.visible = !asset.visible;

  }

  waveVisibility(elt) {
    let wave1 = this.scene.getObjectByName(elt + "_wave1");
    let wave2 = this.scene.getObjectByName(elt + "_wave2");
    wave1.visible = !wave1.visible;
    wave2.visible = !wave2.visible;

  }

  visibilitySlider(event) {
    console.log('this.pointsCloud - ', this.pointsCloud)
    this.pointsCloud.visible = true;
    this.sliderValue = event.value;

    if (this.sliderValue > 0) {
      this.pointsCloud.material.transparent = true;
      this.pointsCloud.material.opacity = 1 - this.sliderValue;
    } 
    else if (this.sliderValue < 0) {
      this.telcoObjGrp.traverse((child) => { this.sliderCAD(child, this.sliderValue); });
    }
    if (this.sliderValue == 1) {
      this.pointsCloud.visible = false;
    }

  }

  sliderCAD(child, sliderValue) {
    if (child instanceof THREE.Mesh) {
      child.material.transparent = true;
      child.material.opacity = 1 + parseFloat(sliderValue);
    }
  }

  openModal(link) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = "100vh";
    dialogConfig.maxWidth = "100%";
    dialogConfig.width = "100%";
    dialogConfig.data = { link: link };
    this.dialog.open(ShelterModalComponent, dialogConfig).afterClosed().subscribe(() => { this.selectedShelter = null; });
  }

  clickEvent(event) {
    // let rect = this.container.domElement.getBoundingClientRect()
    // this.mouse.x = (event.clientX / this.container.nativeElement.offsetWidth) * 2 - 1;
    //  this.mouse.y = - (event.clientY / this.container.nativeElement.offsetHeight) * 2 + 1;
    // let rect = event.target.getBoundingClientRect();
    //   let dx = event.clientX - rect.x;
    //    let dy = event.clientY - rect.y;
    //     this.mouse.x = (dx / rect.width) * 2 - 1;
    //     this.mouse.y = (dy / rect.height) * 2 + 1;
    // console.log(this.mouse)
    //  this.mouse.x = (event.clientX / rect.width) * 2 - 1;
    //  this.mouse.y = - (event.clientY / rect.height) * 2 + 1;
    //  console.log(this.mouse)
    // this.raycaster.setFromCamera(this.mouse, this.camera);
    // let intersects = this.raycaster.intersectObjects(this.assets, true);
    // let intersected = intersects.length > 0 ? intersects[0] : null;
    // console.log(intersects[0])
    // if (intersected !== null) {
    //   for (let i = 0; i < this.auditData.Equipment.length; i++) {
    //     if (this.auditData.Equipment[i].Name == intersected.object.parent.name) {
    //       this.toggleEquipments(intersected.object.parent.name, i);
    //     }
    //   }

    // }
  }


  ngAfterViewInit() {


  }

  ngOnChanges() {
  }

  main() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#272727');
    this.camera = new THREE.PerspectiveCamera(75, this.container.nativeElement.offsetWidth / this.container.nativeElement.offsetHeight, 0.1, 100000);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true
    });

    this.renderer.setSize(this.container.nativeElement.offsetWidth, this.container.nativeElement.offsetHeight);

    this.container.nativeElement.appendChild(this.renderer.domElement);

    this.camera.position.x = this.cameraData.Position.x;
    this.camera.position.y = this.cameraData.Position.y;
    this.camera.position.z = this.cameraData.Position.z;
    this.camera.rotation.x = this.cameraData.Rotation.x;
    this.camera.rotation.y = this.cameraData.Rotation.y;
    this.camera.rotation.z = this.cameraData.Rotation.z;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.25;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.minDistance = 10;
    this.controls.zoomSpeed = 0.3;
    this.controls.rotateSpeed = 0.3;



    var light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(4, 5, 4);
    this.scene.add(light);

    var light1 = new THREE.DirectionalLight(0xffffff, 1);
    light1.position.set(-8, 6, 7);
    this.scene.add(light1);

    var light2 = new THREE.DirectionalLight(0xffffff, 1);
    light2.position.set(2.1, -3.63, -1.27);
    this.scene.add(light2);

    // var axesHelper = new THREE.AxesHelper( 500000);
    // this.scene.add( axesHelper );

    var gltfLoader = new GLTFLoader();
    gltfLoader.load(this.cadData["Link"], (gltf) => {   //'/digitaltwin/DigitalTwin%2FESPERANCERT%2Fscene.glb?alt=media&token=ugi'
      this.addGLTF(gltf);
    });


    this.animate();

  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.camera.aspect = this.container.nativeElement.offsetWidth / this.container.nativeElement.offsetHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.container.nativeElement.offsetWidth, this.container.nativeElement.offsetHeight);
  }


  addEquipments() {
    console.log(this.addEquipment)
    if (this.addEquipment === 'Antenna1') {
      this.addAsset('assets/models/equipment/antenna1.gltf');
    }
    if (this.addEquipment === 'Dish1') {
      this.addAsset('assets/models/equipment/dish1.gltf');

    }
    if (this.addEquipment === 'Dish2') {
      this.addAsset('assets/models/equipment/dish2.gltf')
    }
  }


  RemoveGizmoFromScene() {
    if (this.transformControl != null) {
      this.transformControl.detach();
      this.transformControl.dispose();
      this.scene.remove(this.transformControl);
      this.transformControl = null;
      this.transformControl = this.transformControl;
    }
  }


  scale() {
    // console.log('scaleBut');
    this.transformControl.mode = "scale";
  }
  rotate() {
    // console.log('rotBut');
    this.transformControl.mode = "rotate";
  }
  translate() {
    // console.log('posBut');
    this.transformControl.mode = "translate";
  }

  transformOnDraggingChanged(event) {

    this.controls.enabled = !event.value;

    // console.log('obj pos - ', objNw.position.x, ',', objNw.position.y, ',', objNw.position.z);
    // console.log('obj rot - ', objNw.rotation.x, ',', objNw.rotation.y, ',', objNw.rotation.z);
    // console.log('obj scale - ', objNw.scale.x, ',', objNw.scale.y, ',', objNw.scale.z);
  }

  transformControlOnObj() {

    this.transformControl = new TransformControls(this.camera, this.renderer.domElement);
    // transformControl.addEventListener('change', render);

    this.transformControl.addEventListener('dragging-changed', this.transformOnDraggingChanged.bind(this));
    // this.control.addEventListener( 'dragging-changed', ( event ) => this.orbit.enabled = ! event.value);

    this.transformControl.attach(this.sphere);
    this.scene.add(this.transformControl);
    // this.transformControl = transformControl;
  }

  loadGLTF(gltf) {
    this.RemoveGizmoFromScene();
    this.sphere = gltf.scene.children[0];
    this.scene.add(this.sphere);
    if (this.addEquipment === 'Antenna1') {
      this.sphere.scale.set(1.8, 1.8, 1.8)
    } else {
      this.sphere.scale.set(0.8, 0.8, 0.8)
    }

  }


  removeControls(){
    this.RemoveGizmoFromScene();
    if (this.abc != null) {
      this.scene.remove(this.abc);
      this.objects = [];
    }
  }

  dragSt() {
    this.RemoveGizmoFromScene();
    this.controls.enabled = false;

  }
  dragEd() {

    this.controls.enabled = true;
    this.transformControlOnObj();
  }

  dragging() {
    let pointFrom = this.abc.position;
    let target = new THREE.Vector3();
    let distMin = 1000000000;

    for (let index = 0; index < this.linePts.length; index++) {
      let lineNw = new THREE.Line3(new THREE.Vector3(this.linePts[index].x1, this.linePts[index].y1, this.linePts[index].z1), new THREE.Vector3(this.linePts[index].x2, this.linePts[index].y2, this.linePts[index].z2));

      let targetTmp = new THREE.Vector3();
      lineNw.closestPointToPoint(pointFrom, true, targetTmp)

      // console.log('targetTmp - ', targetTmp);

      let lineNw2 = new THREE.Line3(pointFrom, targetTmp);
      let distNw = lineNw2.distance();
      // console.log('distNw - ', distNw);

      if (distNw < distMin) {
        target = targetTmp.clone();
        distMin = distNw;
      }
    }

    // console.log('target - ', target);
    this.sphere.position.set(target.x, target.y, target.z);

    if (this.addEquipment === 'Antenna1') {
      if (target.x > 0 && (Math.abs(target.x) > Math.abs(target.z))) {
        // console.log('HERE00');
        this.sphere.rotation.y = -Math.PI / 2
        this.sphere.rotation.x = 0
        this.sphere.rotation.z = 0
      }
      else if (target.x < 0 && (Math.abs(target.x) > Math.abs(target.z))) {
        // console.log('HERE02');
        this.sphere.rotation.y = Math.PI / 2 + 0.785;
        this.sphere.rotation.x = 0
        this.sphere.rotation.z = 0
      }
      else if (target.z > 0 && (Math.abs(target.z) > Math.abs(target.x))) {
        // console.log('HERE03');
        this.sphere.rotation.y = Math.PI + 0.785;
        this.sphere.rotation.x = 0
        this.sphere.rotation.z = 0
      }
      else if (target.z < 0 && (Math.abs(target.z) > Math.abs(target.x))) {
        // console.log('HERE04');
        this.sphere.rotation.y = 0 - 0.785;
        this.sphere.rotation.x = 0
        this.sphere.rotation.z = 0
      }
    }
    else {
      if (target.x > 0 && (Math.abs(target.x) > Math.abs(target.z))) {
        // console.log('HERE');
        this.sphere.rotation.y = 0
        this.sphere.rotation.x = Math.PI / 2
        this.sphere.rotation.z = Math.PI / 2
      }
      else if (target.x < 0 && (Math.abs(target.x) > Math.abs(target.z))) {
        // console.log('HERE2');
        this.sphere.rotation.y = 0
        this.sphere.rotation.x = Math.PI / 2
        this.sphere.rotation.z = -Math.PI / 2
      }
      else if (target.z > 0 && (Math.abs(target.z) > Math.abs(target.x))) {
        // console.log('HERE3');
        this.sphere.rotation.y = 0
        this.sphere.rotation.x = -Math.PI / 2
        this.sphere.rotation.z = 0
      }
      else if (target.z < 0 && (Math.abs(target.z) > Math.abs(target.x))) {
        // console.log('HERE4');
        this.sphere.rotation.y = 0
        this.sphere.rotation.x = Math.PI / 2
        this.sphere.rotation.z = 0
      }
      // this.sphere.lookAt( new THREE.Vector3(abc.position.x, abc.position.y, abc.position.z))
      // abc.lookAt( new THREE.Vector3(sphere.position.x, this.sphere.position.y, this.sphere.position.z))
      // console.log('dragging - ', parseFloat(abc.position.x));
      // positionAndAlign2(sphere,  new THREE.Vector3(parseFloat(target.x), parseFloat(target.y), parseFloat(target.z)),  new THREE.Vector3(parseFloat(abc.position.x), parseFloat(abc.position.y), parseFloat(abc.position.z)))
    }
  }
  addAsset(assetLoc) {



    var loader = new GLTFLoader();
    if (this.abc != null) {
      this.scene.remove(this.abc);
      this.objects = [];
    }

    var geometry = new THREE.BoxBufferGeometry(2, 2, 2);
    var material = new THREE.MeshStandardMaterial({ color: 0xff00ff });
    this.abc = new THREE.Mesh(geometry, material);
    this.scene.add(this.abc);
    this.abc = this.abc;
    this.abc.position.set(0, 25, 10);
    this.objects.push(this.abc);


    loader.load(assetLoc, this.loadGLTF.bind(this));

    this.dragControls = new DragControls(this.objects, this.camera, this.renderer.domElement);
    this.dragControls.addEventListener('dragstart', this.dragSt.bind(this));
    this.dragControls.addEventListener('dragend', this.dragEd.bind(this));
    this.dragControls.addEventListener('drag', this.dragging.bind(this));

  }


  addGLTF(gltf) {
    this.telcoObjGrp = gltf.scene;
    this.scene.add(this.telcoObjGrp);

    // this.scene.traverse((child) => { this.sliderCAD(child, this.sliderValue); });
    this.pointsCloud = this.scene.getObjectByName("pcdMesh");
    // console.log('this.scene - ', this.scene);


  }

  materialRenderOrder(elt, order) {
    elt.material.renderOrder = order;
    elt.material.transparent = true;
    elt.material.opacity = 0.5;
    elt.visible = false;
    elt.material.depthWrite = false;
  }


  changeCameraPositionWithRequestAnimationFrame(finalPos, maxX, maxY, maxZ) {
    this.stepsAbc = 0;
    if (Math.abs(maxX) > Math.abs(maxZ)) {
      if (Math.sign(maxX) === 1) {
        this.initialPosAbc = new THREE.Vector3(this.cameraData.Position.x, this.cameraData.Position.y, this.cameraData.Position.z);

      } else {
        this.initialPosAbc = new THREE.Vector3(-this.cameraData.Position.x, this.cameraData.Position.y, this.cameraData.Position.z);
      }
    }
    if (Math.abs(maxZ) > Math.abs(maxX)) {
      if (Math.sign(maxZ) === 1) {
        this.initialPosAbc = new THREE.Vector3(this.cameraData.Position.x, this.cameraData.Position.y, this.cameraData.Position.z);
      } else {
        this.initialPosAbc = new THREE.Vector3(this.cameraData.Position.x, this.cameraData.Position.y, - this.cameraData.Position.z);
      }
    }
    this.finalPosAbc = finalPos;

    this.lookAtObjAbc = finalPos;
    this.reqAnimGlobalId = requestAnimationFrame(() => { this.repeatOften() })
  }

  repeatOften() {
    this.stepsAbc += 0.02;

    this.newPositionAbc = new THREE.Vector3(0, 0, 0);
    this.newPositionAbc.lerpVectors(this.initialPosAbc, this.finalPosAbc, this.stepsAbc);
    this.camera.position.set(
      this.newPositionAbc.x,
      this.newPositionAbc.y,
      this.newPositionAbc.z
    );

    this.controls.target = this.lookAtObjAbc;
    this.controls.update();

    if (this.stepsAbc > 0.8) {
      cancelAnimationFrame(this.reqAnimGlobalId);
    } else {
      requestAnimationFrame(() => { this.repeatOften() });
    }

  }

  closeBox() {
    this.isOpen = false;
  }

  left() {
    this.camera.position.x -= 1;
  }

  right() {
    this.camera.position.x += 1;
  }

  down() {
    this.camera.position.y -= 1;
  }

  up() {
    this.camera.position.y += 1;

  }

  setImage(index) {
    this.crntImg = 0;
    this.detailImageIndex = index;
  }


  editData(key, value, section, detailIndex, sectionIndex, bool, sectionName = null) {
    if (sectionName != null) {
      for (let i = 0; i < this.auditData['Equipment'].length; i++) {
        if (this.auditData['Equipment'][i].Name === sectionName) {
          sectionIndex = i;
        }
      }
    }
    const dialogRef = this.dialog.open(EditComponent, {
      width: '450px',
      maxHeight: '850px',
      data: { value: value, name: key, leg: (section == "Equipment" && key == "Value" && detailIndex == 1) ? true : false }
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result == undefined) { this.imageUpdateArray = undefined; this.updatedValue = undefined; return; }
        if (typeof (result.value) == 'object') {
          this.imageUpdateArray = result.value;
          this.updatedValue = '';
          this.updatedOption = 1;
        }
        if (typeof (result.value) == 'string') {
          if (result.value == undefined) {
            this.updatedValue = '';
          } else {
            this.updatedValue = result.value;
          }
          if (result.option == null || result.option == undefined) {
            this.updatedOption = 1
          }
          else {
            this.updatedOption = result.option;
          }
        }


      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.imageUpdateArray == undefined && this.updatedValue == undefined) { return; }
        if (bool == true) {
          if (this.auditData[section][sectionIndex].Name != this.updatedValue) {
            this.auditData[section][sectionIndex].Name = this.updatedValue;
            this.updateData();
          }
        }
        else if (this.imageUpdateArray != undefined && this.imageUpdateArray.length) {
          this.imageUpdateArray = this.imageUpdateArray.filter(elt => {
            return (elt == "" || elt == null) ? false : true;
          })
          this.auditData[section][sectionIndex].Details[detailIndex][key] = this.imageUpdateArray;
          this.updateData();
        }
        else if (this.updatedValue != '' || this.updatedValue != undefined) {
          if (section == 'Equipment' && detailIndex == 0 && key == 'Value') {
            this.auditData[section][sectionIndex].Name = this.updatedValue;
            this.updateAlias(value,this.updatedValue);
          }
          if (section == 'Equipment' && detailIndex == 1 && key == 'Value') {
            this.auditData[section][sectionIndex].Leg = this.updatedValue;
          }
          if (key === 'Value' && this.updatedOption !== undefined) {
            this.auditData[section][sectionIndex].Details[detailIndex]['Option'] = this.updatedOption;

          }
          if (this.auditData[section][sectionIndex].Details[detailIndex][key] != this.updatedValue) {
            this.auditData[section][sectionIndex].Details[detailIndex][key] = this.updatedValue;
          }

          this.updateData();
        }
        this.imageUpdateArray = [];
        this.updatedValue = '';
        this.updatedOption = undefined;
      }
    )


  }


  updateAlias(prevValue,updatedValue) {
    for (let i = 0; i < this.equipmentAlias.length; i++) {
      if (this.equipmentAlias[i].Alias == prevValue) {
        this.equipmentAlias[i].Alias = updatedValue;
      }
    }
    this.service.setData(this.tower).update({
      DigitalTwin: {
        OutDoorEquipment: this.outDoorEquipments,
        CAD: this.cadData,
        Camera: this.cameraData,
        Equipment: this.equipmentAlias
      }
    }).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })
  }


  updateData() {
    this.service.setData(this.tower).update({
      Audit: this.auditData
    }).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })

    let count = [0, 0, 0, 0];
    for (let i = 0; i < this.auditData['General'].length; i++) {
      let details = this.auditData['General'][i]['Details'];
      for (let j = 0; j < details.length; j++) {
        if (details[j].Option === 1 && details[j].Value != "" && details[j].Value != null) count[0]++;
        if (details[j].Option === 2 && details[j].Value != "" && details[j].Value != null) count[1]++;
        if (details[j].Option === 3 && details[j].Value != "" && details[j].Value != null) count[2]++;
        if (details[j].Option === 4 && details[j].Value != "" && details[j].Value != null) count[3]++;
      }
    }
    for (let i = 0; i < this.auditData['Equipment'].length; i++) {
      for (let j = 0; j < this.auditData['Equipment'][i]['Details'].length; j++) {
        let details = this.auditData['Equipment'][i]['Details'];
        if (details[j].Option === 1 && details[j].Value != "" && details[j].Value != null) count[0]++;
        if (details[j].Option === 2 && details[j].Value != "" && details[j].Value != null) count[1]++;
        if (details[j].Option === 3 && details[j].Value != "" && details[j].Value != null) count[2]++;
        if (details[j].Option === 4 && details[j].Value != "" && details[j].Value != null) count[3]++;
      }
    }
    this.service.towersData(this.year).get().subscribe(
      (result) => {
        this.towers = result.data()['Towers']
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.towers.forEach((elt) => {
          if (elt.Tower.replaceAll(" ", "") == this.tower) {
            elt.Monitor = count[0];
            elt.Planned = count[1];
            elt.Urgent = count[2];
            elt.Emergency = count[3];

          }
        })
        this.service.towersData(this.year).update({
          Towers: this.towers
        }).catch(err => {
          this.snackBar.open(err, "close", { duration: 2000 });
        })
      }
    );

  }


  loadMsg() {
    setTimeout(() => { this.loaded = true; }, 2000);
  }

}
