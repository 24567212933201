import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {MatTreeModule} from '@angular/material/tree';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';



import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";

import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthService } from './auth.service';
import { GuestComponent } from './roles/guest/guest.component';
import { PilotComponent } from './roles/pilot/pilot.component';
import { TelstraAdminComponent } from './roles/telstra-admin/telstra-admin.component';
import { AeroAdminComponent } from './roles/aero-admin/aero-admin.component';
import { UnsetComponent } from './components/unset/unset.component';


import { FilesComponent } from './components/docs/files/files.component';
import { FileviewerComponent } from './components/docs/fileviewer/fileviewer.component';
import { DigitaltwinComponent } from './components/digitaltwin/digitaltwin.component';
import { DocsComponent } from './components/docs/docs.component';
import { OrthomapComponent } from './components/orthomap/orthomap.component';
import { SpinViewComponent } from './components/spin-view/spin-view.component';
import { SummaryComponent } from './components/summary/summary.component';
import { CategoriesComponent } from './components/summary/categories/categories.component';
import { InspectionDetailsComponent } from './components/summary/inspection-details/inspection-details.component';
import { SiteApprovalComponent } from './components/summary/site-approval/site-approval.component';
import { SiteVerificationComponent } from './components/summary/site-verification/site-verification.component';
import { TenantsComponent } from './components/summary/tenants/tenants.component';
import { TableComponent } from './components/table/table.component';
import { TowerAuditComponent } from './components/tower-audit/tower-audit.component';
import { AuditImageComponent } from './components/tower-audit/audit-image/audit-image.component';
import { AuditDetailsComponent } from './components/tower-audit/audit-details/audit-details.component';
import { AuditCategoriesComponent } from './components/tower-audit/audit-categories/audit-categories.component';
import { MapComponent } from './components/map/map.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AddDetailsComponent } from './components/add-details/add-details.component';
import { SpinControlsComponent } from './components/spin-view/spin-controls/spin-controls.component';
import { ImageViewComponent } from './components/spin-view/image-view/image-view.component';
import { MapViewComponent } from './components/spin-view/spin-controls/map-view/map-view.component';
import { ImageSliderComponent } from './components/spin-view/spin-controls/image-slider/image-slider.component';
import { EditComponent } from './components/edit/edit.component';
import { AddSectionComponent } from './components/add-section/add-section.component';
import { SuperAdminComponent } from './roles/super-admin/super-admin.component';



import { AgmCoreModule } from '@agm/core';
import { NgxCaptureModule } from 'ngx-capture';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryImageComponent } from './components/gallery/gallery-image/gallery-image.component';
import { GallerySliderComponent } from './components/gallery/gallery-slider/gallery-slider.component';
import { AddDirComponent } from './components/add-dir/add-dir.component';
import { ShelterModalComponent } from './components/digitaltwin/shelter-modal/shelter-modal.component';
import { UsersComponent } from './components/users/users.component';
import { SearchComponent } from './components/search/search.component';
import { EditSummaryComponent } from './components/edit-summary/edit-summary.component';
import { UploadComponent } from './components/upload/upload.component';
import { TowerComponent } from './components/tower/tower.component';
import { DatatreeComponent } from './components/upload/datatree/datatree.component';
import { CableViewComponent } from './components/cable-view/cable-view.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    GuestComponent,
    PilotComponent,
    TelstraAdminComponent,
    AeroAdminComponent,
    UnsetComponent,
    DigitaltwinComponent,
    DocsComponent,
    OrthomapComponent,
    SpinViewComponent,
    SummaryComponent,
    CategoriesComponent,
    InspectionDetailsComponent,
    SiteApprovalComponent,
    SiteVerificationComponent,
    TenantsComponent,
    TableComponent,
    FileviewerComponent,
    FilesComponent,
    TowerAuditComponent,
    AuditImageComponent,
    AuditDetailsComponent,
    AuditCategoriesComponent,
    MapComponent,
    NavbarComponent,
    EditComponent,
    AddSectionComponent,
    SuperAdminComponent,
    AddDetailsComponent,
    SpinControlsComponent,
    ImageViewComponent,
    MapViewComponent,
    ImageSliderComponent,
    GalleryComponent,
    GalleryImageComponent,
    GallerySliderComponent,
    AddDirComponent,
    ShelterModalComponent,
    UsersComponent,
    SearchComponent,
    EditSummaryComponent,
    UploadComponent,
    TowerComponent,
    DatatreeComponent,
    CableViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatTreeModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatPaginatorModule,
    FormsModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyAzOBa1-qWGLjVHXg3--Axh8MrHZdsUFsw",
      authDomain: "vtelco-true.firebaseapp.com",
      projectId: "vtelco-true",
      storageBucket: "vtelco-true.appspot.com",
      messagingSenderId: "379674708148",
      appId: "1:379674708148:web:12ce10825fc7d0cce86fe1"
    }),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatIconModule,
    MatSnackBarModule,
    HttpClientModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatChipsModule,
    MatTableModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSliderModule,
    NgSelectModule,
    NgxDropzoneModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDz0iaw069jmolmRRRzRTRaCOCtdsSoaMg',
      libraries: ['geometry']
    }),
    NgxCaptureModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
