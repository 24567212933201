<div class="navBar" style="padding: 15px 0px;">
  <app-navbar [main]="false"></app-navbar>
</div>

<div class="cableview">
  <div class="cableviewTab">

    <div class="row" *ngIf="tempTowerName == 'PTT1099(GRF)' ">

      <div class="col-10">
        <div class="wrap">

          <iframe id="frame" class="frame" src="https://panoview-97c96.web.app/indexPTT1099__0008.html"
            frameborder="0"></iframe>
        </div>
      </div>
      <div class="col-2">
        <div class="rightContainer pt-5">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h2 class="levelHeading">List of Views</h2>
            <div class="viewList">
              <ng-container *ngFor="let elt of list1;let i = index">
                <div class="crntView" class="rows" [ngClass]="{ 'selectedView' : crntDir == i  }">
                  <p class="dirText" (click)="crntDir = i;setLink(webList1[i]) ">{{ elt }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row" *ngIf="tempTowerName == 'PTT6032(GRF)' ">

      <div class="col-10">
        <div class="wrap">

          <iframe id="frame" class="frame" src="https://panoview-97c96.web.app/indexPTT6032__0003.html"
            frameborder="0"></iframe>
        </div>
      </div>
      <div class="col-2">
        <div class="rightContainer pt-5">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h2 class="levelHeading">List of Views</h2>
            <div class="viewList">
              <ng-container *ngFor="let elt of list2;let i = index">
                <div class="crntView" class="rows" [ngClass]="{ 'selectedView' : crntDir == i  }">
                  <p class="dirText" (click)="crntDir = i;setLink(webList2[i])">{{ elt }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row" *ngIf="tempTowerName == 'PTT7159(RFT)' ">

      <div class="col-10">
        <div class="wrap">

          <iframe id="frame" class="frame" src="https://panoview-97c96.web.app/indexPTT7159__0013.html"
            frameborder="0"></iframe>
        </div>
      </div>
      <div class="col-2">
        <div class="rightContainer pt-5">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <h2 class="levelHeading">List of Views</h2>
            <div class="viewList">
              <ng-container *ngFor="let elt of list3;let i = index">
                <div class="crntView" class="rows" [ngClass]="{ 'selectedView' : crntDir == i  }">
                  <p class="dirText" (click)="crntDir = i;setLink(webList3[i])">{{ elt }}</p>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>



  </div>
</div>