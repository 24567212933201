import { Component, OnInit, Input, Output, EventEmitter,OnChanges } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { AuditFetchService } from '../audit-fetch.service';
import { ExcelService } from '../excel.service';

@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css']
})
export class AuditDetailsComponent implements OnInit,OnChanges {

  @Input() data : Object;

  @Input() item : Object;

  @Input() role: String;
  deleteIndex = [];
  mode = null;


  @Output() imageEvent = new EventEmitter<any>();


  @Output() editEvent = new EventEmitter<any>();


  @Output() addDeleteEvent = new EventEmitter<any>();

  selected : String;

  ngOnChanges() {
    if(this.item == 'General') {
      this.selected = "General";
      return;
  }
    if(this.item == 'Equipment') {
      this.selected = "Equipment";
      return;
    }
     this.selected = "Details";
     return;
  }


  constructor(private excel: ExcelService,private audit:AuditFetchService) { }

  ngOnInit(): void {
    this.selected = "General";
  }

  selectDetails(event) {
    let chip = event.target;
    this.selected = chip.getAttribute("value");
    }

    mmc(item){
      return item.Option !== 1;
    }

    sendImage(src : String){
      this.imageEvent.emit(src);
    }


    editData(key,value,section,detailIndex,sectionIndex,bool,sectionName = null){
      this.editEvent.emit({key,value,section,detailIndex,sectionIndex,bool,sectionName});
    }

    addSection(){
      this.addDeleteEvent.emit({ mode : 'Add' , add: {sectionIndex : this.item['sectionIndex'], section :  this.item['section'] }});
    }

    deleteItems(event,name ,section, detailIndex, sectionIndex){
      if(event.target.checked){
        this.deleteIndex.push({name,detailIndex , section,  sectionIndex});
      }else{
      this.deleteIndex = this.deleteIndex.filter(elt =>{
        return (elt.name != name && elt.detailIndex != detailIndex && elt.sectionIndex == sectionIndex || (elt.detailIndex == detailIndex && elt.sectionIndex != sectionIndex));//
      });
      }
    }


    deleteSection(){
      if(this.mode == 'Delete'){
        if(this.deleteIndex.length > 0){
       this.addDeleteEvent.emit({delete: this.deleteIndex, mode : 'Delete'});
        }
        this.deleteIndex = [];
      this.mode = null;
    }else{
      this.mode = 'Delete';
      this.deleteIndex = [];
    }
    }
    async getExcel(){
      this.excel.generateExcel(this.audit.audit_data);
      
    }
  

}
