import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as S3 from 'aws-sdk/clients/s3';
// import { s31s } from "s3-ls";
import { AWS } from "aws-sdk/dist/aws-sdk-react-native";


// import { boto3 }  from 'boto3';

// const s31s = require("s3-ls")

// const { files, folders } = lister.ls("/my-folder/subfolder/");

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  //S3 Credentials
  s3 = new S3({
    accessKeyId: "AKIASGGDYK7Z456YDMV5",
    secretAccessKey: "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",
    region: "ap-southeast-1"
  });


  towerName;
  // s31 = boto3.resource('s3')
  existingFiles = [];
  data1;
  data2;
  // data2 = [{filename: 'file 1'}, {filename: 'file 2'}, {filename: 'file 3'}, {filename: ''}, {filename: 'subfile 1'}, {filename: 'subfile 2'}, {filename: 'subfile 3'}, {filename: ''}, {filename: 'subfile 4'}, {filename: 'subfile 5'}];
  docTree;
  lister;
  params;
  test1;
  pleasework = [];
  constructor(private fireStore: AngularFirestore) { }

  ngOnInit() {
  }


//TowerName is fetched
  fetchData(towerName) {

    return this.fireStore.collection(towerName).doc(towerName).get();
  }

  //Bucket data is retireved  
  getS3() {

    const params = {
      Bucket: "demo-pilot-upload",
      Prefix: this.towerName + '/',
    }

    this.data1 = this.s3.listObjectsV2(params, function (err, data) {

      if (err) {
        console.log('ther was an error getting files:' + err);
        // return;        
      }
      if (data) {
        // console.log(data);
        
        this.test1 = data;
        return data;
      }
    })
  }

  //Bucket data is organized
  async buildData() {
    this.existingFiles = []
    for (let i = 0; i < this.data1.response.data.Contents.length; i++) {
      let filePath = this.data1.response.data.Contents[i].Key;
      // console.log(filePath);
      
      let file1 = filePath.substring((filePath.indexOf('/')+1), filePath.length)
      // console.log(file1.split('/').length);
      
      // console.log(file1);
      // console.log(file1.pop(0))
      
      let file = filePath.split('/').pop()
      this.existingFiles.push({ filename: file1, subDir: (file1.split('/').length -1) })

      // console.log(file1.split('/'));
    }
    this.data2 = this.existingFiles;
    // console.log(this.data2);
    
    
  }

  //Object is generated for File Directory structure
  generateObj() {
    this.docTree = []
    let f2 = -1;
    let num;
    
    if (this.data2) {
      let file = []
      let subFolder = [];           
      let subFolder1 = []; let subFolder1_1 = [];   
      let itterationCount = 0;      
      // console.log(this.data2);
      
      this.docTree.push(
        {
          name: this.towerName,
          type: 'folder',
          children: []
        })

        if (this.data2.length > 1) {
          let fcount = [true];
          // console.log(this.data2.length);
          let fc= 0
          let fc1 =1;
          for(itterationCount = 0; itterationCount < 2; itterationCount++){

            console.log('it run', itterationCount, 'times');
            

          for (let i = 0; i < this.data2.length; i++) {
            if (this.data2[i].subDir == 0 && !file.includes(this.data2[i].filename)) {
              this.docTree[0].children.push({
                name: this.data2[i].filename,
                type: 'file'
              })
              file.push(this.data2[i].filename)
              f2++;
              
            } 
            if (this.data2[i].subDir == 1 && itterationCount == 0) { 
            // console.log(this.data2[i]);
              
            let f1 = 0;            
            let name1 = this.data2[i].filename.substring(0, this.data2[i].filename.indexOf('/'))
            let n1= 0;
            let n2= 0; 
          
            if(name1){
              if(!subFolder.includes(name1)){subFolder.push(name1)}
              // console.log(subFolder);         
              for(let nc=i; nc< this.data2.length; nc++){             
                if(this.data2[nc]){
                  let name2 = this.data2[nc].filename.substring(0, this.data2[nc].filename.indexOf('/'))           
                  if(name2 == name1)
                  {n1++}
                  if(name2 != name1){n2++} }
              }
            }
            if(fc < subFolder.length){
              // console.log('this is f1', fc );
             this.docTree[f1].children.push({
              name:  name1,
              type: 'folder',
              children: []
            })
            // console.log(name1);
            
            fc++;
            f2++;
            for(let fc1 = 0; fc1< n1; fc1++){
              if(this.data2[i+fc1].subDir == 1 && itterationCount == 0){
              let name2 = this.data2[i+fc1].filename.substring((this.data2[i].filename.indexOf('/')+1), this.data2[i].filename.length)              
              this.docTree[0].children[f2].children.push({
                name: name2,
                type: 'file'
              })
            }
            }        
          }
          }    
          if (this.data2[i].subDir == 2 && itterationCount == 1) {
            let f1 = 0;            
            let name1 = this.data2[i].filename.substring(0, this.data2[i].filename.indexOf('/'))
            let name1_0 = this.data2[i].filename.substring((this.data2[i].filename.indexOf('/')+1), this.data2[i].length);
            let name1_1 = name1_0.substring(0,name1_0.indexOf('/'))
            let name1_2 = name1_0.substring((name1_0.indexOf('/')+1), name1_0.length);
            let folIndex = this.docTree[f1].children.findIndex( f => f.name == name1);   
            let n1= 0;let n2= 0; 
              if(!subFolder1_1.includes(name1_1)){
             this.docTree[f1].children[folIndex].children.push({
              name:  name1_1,
              type: 'folder',
              children: []
            })
          }
            fc++;
            f2++;
          if(name1_1){          
             let folIndex_f = this.docTree[f1].children[folIndex].children.findIndex( f => f.name == name1_1);
             this.docTree[f1].children[folIndex].children[folIndex_f].children.push({
               name: name1_2,
               type: 'file',

             })
        }

//Creates an array with folder names ans sub-folder names
            if(name1){
              if(!subFolder1.includes(name1)){subFolder1.push(name1)}
              if(!subFolder1_1.includes(name1_1)){subFolder1_1.push(name1_1)}
              console.log(subFolder1_1);         
              for(let nc=i; nc< this.data2.length; nc++){             
                if(this.data2[nc]){
                  this.data2[nc]
                  let name2 = this.data2[nc].filename.substring(0, this.data2[nc].filename.indexOf('/'))           
                  if(name2 == name1)
                  console.log(name2);
                  
                  {n1++}

                  if(name2 != name1){n2++} }
              }
            }
          }               
         
        }  
        console.log('another ride');
      }      
      
      }
    }
    // console.log(this.docTree);
    
    return this.docTree;
  }

  getImageUrl(img){

    
    const params1 = {
      Bucket: "demo-pilot-upload",
      Key: this.towerName + '/' + img,
    }

    let imgURL = this.s3.getSignedUrl('getObject', params1)

    // console.log(imgURL);
    return imgURL
    


  }


}
