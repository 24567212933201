import { Component, OnInit, HostListener } from '@angular/core';
import { UploadService } from './upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';


import * as S3 from 'aws-sdk/clients/s3';
import { ExDataService } from './ex-data.service';



/** File node data with possible child nodes. */
export interface FileNode {
  name: string;
  type: string;
  children?: FileNode[];
}

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
  name: string;
  type: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit {
  
  
  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;
  
  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<FileNode, FlatTreeNode>;
  
  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<FileNode, FlatTreeNode>;

  

  s3 = new S3({
    accessKeyId: "AKIASGGDYK7Z456YDMV5",
    secretAccessKey: "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",
    region: "ap-southeast-1"
  });
  testData = "akhil";
  uploadList;
  tempUploadList = [];
  tempUploadList1 = [];

  existingFiles;
  testArr = ['name1', 'name2'];
  towerName;
  role;
  year;
  currUploadNumber;
  totalNumberOfFiles;
  percentUpload;
  // uploadVis = 'visible';
  uploadedHeadVis = 'hidden';
  uploadVis = 'flex';
  // uploadedHeadVis = 'none';

  uploadedHeadDis
  constructor(private upload: UploadService, 
              private snackBar: MatSnackBar, 
              private route: ActivatedRoute, 
              private router: Router,
              public exData: ExDataService) { 

                this.treeFlattener = new MatTreeFlattener(
                  this.transformer,
                  this.getLevel,
                  this.isExpandable,
                  this.getChildren);
            
                this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
                this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
                // this.dataSource.data = files;


              }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params => {
      this.towerName = params.tower;
      this.upload.towerName = params.tower;
      this.year = params.year;
    })

    this.upload.getS3();
    // console.log('test Data - ', this.testData);
    

  }


  /** Transform the data to something the tree can read. */
  transformer(node: FileNode, level: number): FlatTreeNode {
    return {
      name: node.name,
      type: node.type,
      level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode): number {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: FileNode): FileNode[] | null | undefined {
    return node.children;
  }

 

  uploadFolders(files) {
    console.log(files);
    
    if (files != null) {
      this.uploadList = files;
      this.currUploadNumber = 0;
      this.percentUpload = 0;
    }
    this.uploadVis = 'none';
    this.uploadedHeadVis = 'visible';
    // console.log('this.uploadVis - ', this.uploadVis)
    console.log('this.uploadList - ', this.uploadList)
    this.totalNumberOfFiles = this.uploadList.length

    //change this later
    this.tempUploadList = [];

    for (let i = 0; i < this.uploadList.length; i++) {
      let path = this.uploadList[i].webkitRelativePath;
      this.uploadS3(path, this.uploadList[i])
      // console.log(this.uploadList[i]);
      
      this.tempUploadList.push({filename:this.uploadList[i].name, progress: 0});
    }
    
    this.uploadList = []

  }

  // setTimeoutCall(){

  //   console.log('HEEHEE');
  //   this.uploadVis = 'visible';
  //   this.uploadedHeadVis = 'hidden';
  // }

  uploadS3(path, file) {
    console.log(path);
    
    
    let path1 = path.substring(path.indexOf('/'), path.length)
    console.log(path1);
    this.tempUploadList1.push(path1)
    
    path = path.split('/').pop()

    console.log(path);
    
    // console.log('path- ', path);

    let nm_nw = (this.towerName);
    // console.log('this.towerName - ', nm_nw);
    

    // Key: this.towerName + '/' + path,
    // this.s3.putObject({
    // this.s3.upload({
      this.s3.upload({
        Bucket: "demo-pilot-upload",
      Key: nm_nw + path1,
      Body: file,
    }, (res) => {
      // null if successful
      console.log('res - ', res);
      // RequestTimeTooSkewed: The difference between the request time and the current time is too large.
      // IF THIS ERROR, SHOW MESSAGE
      if (res == null){
       
        this.tempUploadList[this.currUploadNumber].progress = 100;           
       
        this.currUploadNumber = this.currUploadNumber+1;
        this.percentUpload = Math.round(((this.currUploadNumber/this.totalNumberOfFiles)*100) * 10) / 10
        this.snackBar.open('Uploaded - '+this.percentUpload+'% OR '+this.currUploadNumber+'/'+this.totalNumberOfFiles+' files', "close", { duration: 5000 });
        
        if (this.currUploadNumber==this.totalNumberOfFiles){
          // console.log('HEEHEE');
          // setTimeout(this.setTimeoutCall, 2000);

          // setTimeout(()=>{

          //   this.uploadVis = 'visible';
          //   this.uploadedHeadVis = 'hidden';
          // }, 10000)
          
          this.snackBar.open('Redirecting to upload page in a moment','', {duration: 5000})
        }

      }
      else{
        this.uploadVis = 'flex';
        this.uploadedHeadVis = 'hidden';
        this.snackBar.open('Error - '+ res, "close", { duration: 5000 });
        console.log('ERROR - ', res);

      }
    });
  }

  
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.getFilesDataTransferItems(event.dataTransfer.items)
    }
  }
  getFilesDataTransferItems(items) {
    for (let item of items) {
      let temp = item.webkitGetAsEntry();
      console.log(temp);
      
      this.findFile(temp, temp.fullPath)
    }
  }
  
  findFile(item, dir) {
    if (item.isFile) {
      item.file(file => {
        file.filepath = dir;
        console.log(file)
        this.uploadS3(dir.replace("/", ""), file);
      });
    }
    if (item.isDirectory) {
      let dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (let entry of entries) {
          this.findFile(entry, entry.fullPath);
        }
      });
    }
  }
  
    }
    