import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExDataService {
  

  private receiveData = new BehaviorSubject<[]>([])
  data = this.receiveData.asObservable();
  data1 = [];
  constructor() { }

  addData(){
  
  
    console.log('hello data');
    
  }

  
}
