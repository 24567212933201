import { Component, OnInit } from '@angular/core';
import { AuditFetchService } from './audit-fetch.service';
import { ActivatedRoute, Router } from "@angular/router";
import { EditComponent } from './../edit/edit.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddSectionComponent } from './../add-section/add-section.component';
import { AddDetailsComponent } from './../add-details/add-details.component'
import { ExcelService } from './excel.service';

@Component({
  selector: 'app-tower-audit',
  templateUrl: './tower-audit.component.html',
  styleUrls: ['./tower-audit.component.css'],
  providers: [AuditFetchService]
})
export class TowerAuditComponent implements OnInit {
  result: Object = {};
  imageArray = [];
  isLoaded: Boolean = false;
  src: String = '';
  item = 'General';
  selected = "All";
  tower = [];
  csv;
  towerName;
  data = [];
  updatedValue = '';
  updatedOption;
  addedValue = '';
  role = null;
  digitwin;
  addedSection = '';
  year;
  imageUpdateArray = [];
  towers = [];
  constructor(private service: AuditFetchService, private route: ActivatedRoute, private dialog: MatDialog, private snackBar: MatSnackBar, private router: Router, private excel: ExcelService) { } //,private route: ActivatedRoute

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }

    this.route.params.subscribe(params => {
      this.year = params.year;
      if (params.selected) {
        this.selected = params.selected;
      }
      this.towerName = params.tower;
      if (this.towerName) {

        this.getTowerData();

      }
    });
    this.csv = ["Name", "Reference", "AntennaLeg", "Carrier"].join(",") + "\n";
  }

  ngOnChanges() {
    this.route.params.subscribe(params => {
      if (params.selected) {
        this.selected = params.selected;
      }
    });
  }

  getTowerData() {
    this.service.fetchData(this.towerName).forEach(data => {
      data.forEach(doc => {
        this.data.push(doc.data());
        this.result = this.data[0].Audit;
        this.imageArray = this.result['General'][1]['Details'][0].Image;
        this.digitwin = this.data[0].DigitalTwin;
      })
    }).then(() => {
      this.isLoaded = true;
    })
  }

  pushDetails(elt) {
    if (elt) {
      for (let j = 0; j < elt.length; j++) {
        let dict;
        dict = {
          Name: elt[j]["Name"],
          Antenna_Reference: elt[j]["Details"][0]["Value"],
          Antenna_Leg: elt[j]["Details"][1]["Value"],
          Carrier: elt[j]["Details"][2]["Value"],
        }
        this.csv += elt[j]["Name"] + ',' + elt[j]["Details"][0]["Value"] + ',' + elt[j]["Details"][1]["Value"] + ',' + elt[j]["Details"][2]["Value"] + "\n";
        this.tower.push(dict);
      }
    }
  }

  downloadCSV(event) {
    let downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(this.csv);
    downloadLink.target = '_blank';
    downloadLink.download = 'AuditReport.csv';
    downloadLink.click();
  }


  ngAfterViewInit() {

  }

  getDetails(items: any) {
    this.item = items;
  }

  setImage(src) {
    this.src = src[0];
    this.imageArray = src;
  }

  editData(event) {
    if(event.sectionName != null){
      for(let i = 0;i < this.result['Equipment'].length;i++){
        if(this.result['Equipment'][i].Name === event.sectionName){
          event.sectionIndex = i;
        }
      }
    }
    const dialogRef = this.dialog.open(EditComponent, {
      width: '450px',
      maxHeight: '850px',
      data: { value: event.value, name: event.key, leg :(event.section == "Equipment" && event.key == "Value" && event.detailIndex == 1)? true : false}
    });
    dialogRef.afterClosed().subscribe(
      result => {
        if (result == undefined) { this.imageUpdateArray = undefined; this.updatedValue = undefined; return; }
        if (typeof (result.value) == 'object') {
          this.imageUpdateArray = result.value;
          this.updatedValue = '';
          this.updatedOption = 1;
        }
        if (typeof (result.value) == 'string') {
          if (result.value == undefined) {
            this.updatedValue = '';
          } else {
            this.updatedValue = result.value;
          }
          if (result.option == null || result.option == undefined) {
            this.updatedOption = 1;
          }
          else {
            this.updatedOption = result.option;
          }
        }


      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.imageUpdateArray == undefined && this.updatedValue == undefined) { return; }
        if (event.bool == true) {
          if (this.result[event.section][event.sectionIndex].Name != this.updatedValue) {
            this.result[event.section][event.sectionIndex].Name = this.updatedValue;
            this.updateData();
          }
        }
        else if (this.imageUpdateArray != undefined && this.imageUpdateArray.length) {
          this.imageUpdateArray = this.imageUpdateArray.filter(elt => {
            return (elt == "" || elt == null) ? false : true;
          })
          this.result[event.section][event.sectionIndex].Details[event.detailIndex][event.key] = this.imageUpdateArray;
          this.updateData();
        }
        else if (this.updatedValue != '' || this.updatedValue != undefined) {
          if (event.section == 'Equipment' && event.detailIndex == 0 && event.key == 'Value') {
            this.result[event.section][event.sectionIndex].Name = this.updatedValue;
            this.updateAlias(event.value,this.updatedValue);
          }
          if (event.section == 'Equipment' && event.detailIndex == 1 && event.key == 'Value') {
            this.result[event.section][event.sectionIndex].Leg = this.updatedValue;
          }
          if (event.key === 'Value' && this.updatedOption !== undefined) {
            this.result[event.section][event.sectionIndex].Details[event.detailIndex]['Option'] = this.updatedOption;

          }
          if (this.result[event.section][event.sectionIndex].Details[event.detailIndex][event.key] != this.updatedValue) {
            this.result[event.section][event.sectionIndex].Details[event.detailIndex][event.key] = this.updatedValue;
          }

          this.updateData();
        }
        this.imageUpdateArray = [];
        this.updatedValue = '';
        this.updatedOption = undefined;
      }
    )


  }



  updateAlias(prevValue,updatedValue) {
    console.log(prevValue,updatedValue, this.digitwin)
    let equipmentAlias = this.digitwin.Equipment
    for (let i = 0; i < equipmentAlias.length; i++) {
      if (equipmentAlias[i].Alias == prevValue) {
        equipmentAlias[i].Alias = updatedValue;
        this.digitwin.Equipment = equipmentAlias;
        this.service.setData(this.towerName).update({
          DigitalTwin: this.digitwin
        }).catch(err => {
          this.snackBar.open(err, "close", { duration: 2000 });
        })
      }
    }
  }


  addSection(event) {
    const dialogRef = this.dialog.open(AddSectionComponent, {
      width: '450px',
      maxHeight: '480px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result.data.Name == null) { this.addedSection = null; this.addedValue = null; return; }
        this.addedSection = result.section;
        this.addedValue = result.data;
      },
      err => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        if (this.addedSection != null && this.addedValue != null) {
          this.result[this.addedSection].push(this.addedValue);
          this.updateData();
        }
      })
  }

  deleteSection(event) {
    event.forEach(data => {
      this.result[data.section] = this.result[data.section].filter(elt => {
        return (elt.Name !== data.name) ? true : false;
      });

      this.updateData();

    });
  }

  updateData() {
    this.service.setData(this.towerName).update({
      Audit: this.result
    }).catch(err => {
      this.snackBar.open(err, "close", { duration: 2000 });
    })

    let count = [0, 0, 0, 0];
    for (let i = 0; i < this.result['General'].length; i++) {
      let details = this.result['General'][i]['Details'];
      for (let j = 0; j < details.length; j++) {
        if (details[j].Option === 1 && details[j].Value != "" && details[j].Value != null) count[0]++;
        if (details[j].Option === 2 && details[j].Value != "" && details[j].Value != null) count[1]++;
        if (details[j].Option === 3 && details[j].Value != "" && details[j].Value != null) count[2]++;
        if (details[j].Option === 4 && details[j].Value != "" && details[j].Value != null) count[3]++;
      }
      console.log('count[0] - ', count[0]);
      
    }
    for (let i = 0; i < this.result['Equipment'].length; i++) {
      for (let j = 0; j < this.result['Equipment'][i]['Details'].length; j++) {
        let details = this.result['Equipment'][i]['Details'];
        if (details[j].Option === 1 && details[j].Value != "" && details[j].Value != null) count[0]++;
        if (details[j].Option === 2 && details[j].Value != "" && details[j].Value != null) count[1]++;
        if (details[j].Option === 3 && details[j].Value != "" && details[j].Value != null) count[2]++;
        if (details[j].Option === 4 && details[j].Value != "" && details[j].Value != null) count[3]++;
      }
    }
    this.service.towersData(this.year).get().subscribe(
      (result) => {
        this.towers = result.data()['Towers']
      },
      (err) => {
        this.snackBar.open(err, "close", { duration: 2000 });
      },
      () => {
        this.towers.forEach((elt) => {
          if (elt.Tower.replaceAll(" ", "") == this.towerName) {
            elt.Monitor = count[0];
            elt.Planned = count[1];
            elt.Urgent = count[2];
            elt.Emergency = count[3];

          }
        })
        this.service.towersData(this.year).update({
          Towers: this.towers
        }).catch(err => {
          this.snackBar.open(err, "close", { duration: 2000 });
        })
      }
    );

  }


  addDeleteData(event) {
    if (event.mode == "Add") {
      const dialogRef = this.dialog.open(AddDetailsComponent, {
        width: '450px',
        maxHeight: '480px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          this.addedValue = result.data;
        },
        err => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          this.result[event.add.section][event.add.sectionIndex].Details.push(this.addedValue);
          this.updateData();
        })
    }
    if (event.mode == "Delete") {
      if (event.delete.length > 0) {
        for (let i = 0; i < event.delete.length; i++) {
          this.result[event.delete[i].section][event.delete[i].sectionIndex].Details = this.result[event.delete[i].section][event.delete[i].sectionIndex].Details.filter(record => {
            return (record.Name === event.delete[i].name) ? false : true;
          });
        };
        this.updateData();
      }

    }
  }
  getExcel(){
    this.excel.generateExcel(this.service.audit_data);
  }


}
