
<div class="navBar">
  <app-navbar [main]="false"></app-navbar>
</div>

<div class="summary">
  <div class="uploadTabs" style="overflow-y: scroll;">
    <!-- <input type="file" (change)="uploadFolders($event.target.files)" accept="image/*" webkitdirectory directory multiple> -->
<div class="container d-flex justify-content-center">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab label="Upload Files">
      <div class="container">
      <mat-card draggable="true" class="dragarea" [ngStyle]="{display: uploadVis}">
        <div class="row">
            <div class="col-md-12 text-center">
             
              
                <span class="choose" (click)="file.click()">
                   Choose
                </span>
                or
                Drag Folders with Images here <br>
                <span class="sub">Folder will be Uploaded to S3 Bucket with same Path</span>
  
                 <input #file 
                  type="file"
                  accept="image/*"
                  webkitdirectory
                  directory
                  multiple
                  (change)="uploadFolders($event.target.files)"
                  style="display:none" />
              
                       
  
            <div class="dragged-files-wrapper" *ngIf="uploadList">
              <div class="file" *ngFor="let file of draggedFiles">
                {{file}}
              </div>
            </div>
          
            </div>
            </div>
                   
      
        </mat-card>
        
        <div id="Uploading" [ngStyle]="{visibility: uploadedHeadVis}"  >
          <!-- <div id="Uploading" [ngStyle]="{display: uploadedHeadDis}"  > -->
      
          <h3>Uploaded: {{ currUploadNumber }} / {{ totalNumberOfFiles }}</h3>
          <h3>Upload Percent: {{ percentUpload }} %</h3>
            <mat-progress-bar mode="determinate" value="{{percentUpload}}"></mat-progress-bar>
          <br>
          <div *ngFor="let f of tempUploadList;index as i" class="mt-2">
            <div class="container shadow rounded pe-5 ps-5 pt-2">
              <div class="row">
                <div class="col">
                  <p class="file-items">
                    {{i+1}} . {{f.filename}}
                </p>
                </div>
                <div class="col-6"><mat-progress-bar mode="determinate" value="{{f.progress}}"></mat-progress-bar></div>
                <p>{{tempUploadList1[i]}}</p>
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Existing Uploads">
      <div class="text-center">
        <br>
        <app-datatree [ExistingFiles] = "testData" ></app-datatree>
      </div>
    </mat-tab>
    </mat-tab-group>
</div>
    
    


  </div>
</div>


