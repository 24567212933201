import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cable-view',
  templateUrl: './cable-view.component.html',
  styleUrls: ['./cable-view.component.css']
})
export class CableViewComponent implements OnInit {

  // iframe = false;
  constructor(private route: ActivatedRoute) { }
tempTowerName;
list1 = [ '1099__0008','1099__0009','1099__0011' ];
list2 = ['6032__0003','6032__0005','6032__0006','6032__0007'];
list3 = ['7159__0013','7159__0014','7159__0015',];
imgSrc;
webList1 = [
  '1099__0008',
  '1099__0009',
  '1099__0011'  
]
webList2 = [
  '6032__0003',
  '6032__0005',
  '6032__0006',
  '6032__0007'
]
webList3 = [
  '7159__0013',
  '7159__0014',
  '7159__0015',
]
crntDir = 0;
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      console.log('params.tower - ', params.tower);
      this.tempTowerName = params.tower
      // this.towerName = params.tower;
      // this.year = params.year;
    })
  }

  setLink(i){
    let link = 'https://panoview-97c96.web.app/indexPTT'+ i +'.html'
    document.getElementById('frame').setAttribute('src', link)
    // src = 'https://panoview-97c96.web.app/indexPTT'+webList1[i]+'.html'
  }

}
