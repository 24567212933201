<button mat-raised-button (click) = "showTree = !showTree ; showValue()">show tree</button>
<div class="container">

<div class="row">
<div class="col" class="fileDirectory">
  <div *ngIf = showTree>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-icon id="fileN" class="type-icon" [attr.aria-label]="node.type + 'icon'" (click)="showFile(node.name)">
          {{ node.type === 'file' ? 'description' : 'folder' }}
        </mat-icon>
        <label for="fileN" (click)="showFile(node.name)">{{node.name}}</label>
        
      </mat-tree-node>
      
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-icon class="type-icon" [attr.aria-label]="node.type + 'icon'">
        {{ node.type ==='file' ? 'description' : 'folder' }}
      </mat-icon>
      <label for="fileN" matTreeNodeToggle>{{node.name}}</label>
    </mat-tree-node>
  </mat-tree>
  </div>
</div>
<div class="col" class="fileView">

  <img [src]= "imgURL" alt="" srcset="">

</div>
</div>
  
</div>