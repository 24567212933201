<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />
<div class="navbar" *ngIf="!main">
  <table class="navTable">
    <tr>
      <td>
        <!-- <a [routerLink]="'/'+role+'/map'"> <img class="logo" src="assets/images/icons/logo.jpg"></a> -->
        <a [routerLink]="'/'+role+'/map'"> <img class="logo" src="assets/images/icons/icon23456.jpg"></a>
      </td>
      <td>
        <!-- <p class="navbarSite">Site Name : <b *ngIf="result.length">{{ result[1]['Details'][0].Value || 'N/A' }}</b></p> -->
        <p class="navbarSite">Site Name : <b>{{ tempTowerName }}</b></p>
      </td>
      <td>
        <p class="navbarSite">Site Code : <b *ngIf="result.length">{{ result[1]['Details'][1].Value || 'N/A' }}</b></p>
      </td>
      <td>
        <p class="navbarSite">Site Audited On : <b *ngIf="result.length">{{ result[0]['Details'][2].Value || 'N/A'}}</b></p>
      </td>
      <td>
        <a *ngIf="role == 'pilot'" [routerLink]="'/'+role+'/'+year +'/'+towerName+'/upload'" class="navbarItems">
          <img src="assets/images/icons/upload.svg" class="icons"><span class="iconText"> Upload </span>
        </a>
        <a *ngIf="role == 'super-admin'" [routerLink]="'/'+role+'/users'" class="navbarItems">
          <img src="assets/images/icons/users.svg" class="icons"><span class="iconText"> Users </span>
        </a>
        <a *ngIf="role != 'super-admin' && role != 'pilot'" (click)="search($event)" class="navbarItems">
          <img src="assets/images/icons/search.svg" class="icons"><span class="iconText"> Search </span>
        </a>
      </td>
      <td>
        <a [routerLink]="'/'+role+'/map'" class="navbarItems">
          <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
        </a>
      </td>
      <td>
        <a [routerLink]="'/'+role+'/table'" class="navbarItems">
          <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
        </a>
      </td>
      <td class="borderLeft" *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/summary'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('summary') }">
          <img src="assets/images/icons/summary.svg" class="icons"><span class="iconText"> Summary </span>
        </a>
      </td>
      <!-- <td *ngIf="role != 'super-admin' && role != 'pilot'" > -->
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/orthomap'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('orthomap') }">
          <img src="assets/images/icons/orthomap.svg" class="icons"><span class="iconText"> Ortho Map </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/digitaltwin'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('digitaltwin') }">
          <img src="assets/images/icons/digitaltwin.svg" class="icons"><span class="iconText"> Digital Twin </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/cableview'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('cableview') }">
          <img src="assets/images/icons/360.png" class="icons"><span class="iconText"> 360 Cabin View </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/view/towerview'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('towerview') }">
          <img src="assets/images/icons/towerview.svg" class="icons"><span class="iconText"> Spin View </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/view/los'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('los') }">
          <img src="assets/images/icons/los.svg" class="icons"><span class="iconText"> Line of Sights </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/view/topdown'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('topdown') }">
          <img src="assets/images/icons/topdown.svg" class="icons"><span class="iconText"> Top to Down </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/audit'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('audit') }">
          <img src="assets/images/icons/audit.svg" class="icons"><span class="iconText"> Audit </span>
        </a>
      </td>
      <td class="borderLeft" *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/docs'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('docs')}">
          <img src="assets/images/icons/docs.svg" class="icons"><span class="iconText"> Docs & Report </span>
        </a>
      </td>
      <td *ngIf="role != 'pilot'">
        <a [routerLink]="'/'+role+'/'+year +'/'+towerName+'/gallery'" class="navbarItems"
          [ngClass]="{'selected' : router.url.endsWith('gallery')}">
          <img src="assets/images/icons/gallery.svg" class="icons"><span class="iconText"> Gallery </span>
        </a>
      </td>
      <td>
        <a (click)="logout()" class="navbarItems">
          <img src="assets/images/icons/logout.svg" class="icons"><span class="iconText"> Logout </span>
        </a>
      </td>
    </tr>
  </table>
</div>
<div class="navbar" *ngIf="main">
  <div class="navbarLeft">
    <a [routerLink]="'/'+role+'/map'">
      <img class="logo" src="assets/images/icons/icon23456.jpg">
    </a>
    <p class="navbarMapSite"></p>
  </div>
  <div class="navbarRight">
     <ng-container *ngIf="currentRoute != 'users'">
       <div  class="search">
        <mat-form-field >
        <mat-icon matSuffix>search</mat-icon>
          <mat-label>Inspected</mat-label>
        <input matInput list="inspectedTowers" (keypress)="stopEvent($event)" (change)="inspectedTower($event)">
        </mat-form-field>
       </div>
       <div  class="search" *ngIf="role == 'pilot'">
        <mat-form-field >
        <mat-icon matSuffix>search</mat-icon>
          <mat-label>Uninspected</mat-label>
        <input matInput list="uninspectedTowers" (keypress)="stopEvent($event)" (change)="uninspectedTower($event)">
        </mat-form-field>
       </div>
       <datalist  id="inspectedTowers">
        <ng-container *ngIf="data.length">
          <ng-container *ngFor="let item of data[crntYear]['Towers']">
            <option *ngIf="item.Status === 'Inspected'" [value]="item.Tower"></option>
          </ng-container>
        </ng-container>
      </datalist>
      <datalist  *ngIf="role == 'pilot'" id="uninspectedTowers">
        <ng-container *ngIf="data.length">
          <ng-container *ngFor="let item of data[crntYear]['Towers']">
            <!-- <option *ngIf="item.Status === 'Uninspected'" [value]="item.Tower">item.Tower</option> -->
            <option *ngIf="item.Status === 'Uninspected'" [value]="item.SiteID+' - '+item.Tower"></option>
          </ng-container>
        </ng-container>
      </datalist>
      <div class="specialColor">
        <span>|</span>
      </div>
    </ng-container>
    <ng-container *ngIf="currentRoute == 'table'">
      <a [routerLink]="'/'+role+'/map'" class="navbarItems">
        <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
      </a>
      <a *ngIf="role == 'super-admin'" [routerLink]="'/'+role+'/users'" class="navbarItems">
        <img src="assets/images/icons/users.svg" class="icons"><span class="iconText"> Users </span>
      </a>
    </ng-container>
    <ng-container *ngIf="currentRoute == 'map'">
      <a [routerLink]="'/'+role+'/table'" class="navbarItems">
        <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
      </a>
      <a *ngIf="role == 'super-admin'" [routerLink]="'/'+role+'/users'" class="navbarItems">
        <img src="assets/images/icons/users.svg" class="icons"><span class="iconText"> Users </span>
      </a>
    </ng-container>
    <ng-container *ngIf="currentRoute == 'users'">
      <a [routerLink]="'/'+role+'/map'" class="navbarItems">
        <img src="assets/images/icons/map.svg" class="icons"><span class="iconText"> Map View </span>
      </a>
      <a [routerLink]="'/'+role+'/table'" class="navbarItems">
        <img src="assets/images/icons/table.svg" class="icons"><span class="iconText"> Table View </span>
      </a>
    </ng-container>

    <div class="specialColor">
      <span>|</span>
    </div>
    <a (click)="logout()" class="navbarItems">
      <img src="assets/images/icons/logout.svg" class="icons"><span class="iconText"> Logout </span>
    </a>
  </div>


</div>
